// React Imports
import type { Theme } from '@mui/material';
import React from 'react';

// MUI Imports

const SelectIcon = () => {
  return <i className='material-symbols-arrow-drop-down-rounded' />;
};

const iconStyles = (theme: Theme) => ({
  userSelect: 'none',
  display: 'inline-block',
  fill: 'currentColor',
  flexShrink: 0,
  transition: theme.transitions.create('fill', {
    duration: theme.transitions.duration.shorter,
  }),
  fontSize: '1.25rem',
  position: 'absolute',
  right: '10px',
  top: 'calc(50% - 0.5em)',
  pointerEvents: 'none',
});

const select: Theme['components'] = {
  MuiSelect: {
    defaultProps: {
      size: 'small',
      IconComponent: SelectIcon,
    },
    styleOverrides: {
      select: ({ theme, ownerState }) => ({
        padding: '5px 32px 5px 12px',
        ...(ownerState.variant === 'outlined' && {
          minHeight: '1.5em',
        }),
        '&[aria-expanded="true"] ~ i, &[aria-expanded="true"] ~ svg': {
          transform: 'rotate(180deg)',
        },
        '& ~ i, & ~ svg': iconStyles(theme as Theme),
        '&.MuiInputBase-inputSizeSmall': {
          '& ~ i, & ~ svg': {
            height: '1.375rem',
            width: '1.375rem',
          },
        },
        '&:not(aria-label="Without label") ~ .MuiOutlinedInput-notchedOutline > legend > span':
          {
            paddingInline: '5px',
          },
      }),
    },
  },
  MuiNativeSelect: {
    styleOverrides: {
      select: ({ theme }) => ({
        '& + i, & + svg': iconStyles(theme as Theme),
      }),
    },
  },
};

export default select;
