import type { Theme } from '@mui/material';

const iconButton: Theme['components'] = {
  MuiIconButton: {
    styleOverrides: {
      root: {
        '& .MuiSvgIcon-root': {
          fontSize: 'inherit',
        },
        borderRadius: '4px',
      },
      sizeSmall: ({ theme }) => ({
        padding: theme.spacing(1.75),
        fontSize: '1.25rem',
      }),
      sizeMedium: ({ theme }) => ({
        padding: theme.spacing(1.75),
        fontSize: '1.5rem',
      }),
      sizeLarge: ({ theme }) => ({
        padding: theme.spacing(1.75),
        fontSize: '1.75rem',
      }),
    },
    variants: [
      {
        props: { color: 'default' },
        style: {
          '&:not(.Mui-disabled):hover, &:not(.Mui-disabled):active': {
            backgroundColor: 'var(--mui-palette-primary-dark)',
          },
          '&.Mui-disabled': {
            opacity: 0.45,
            color: 'var(--mui-palette-action-active)',
          },
        },
      },
      {
        props: { color: 'primary' },
        style: {
          '&:not(.Mui-disabled):hover, &:not(.Mui-disabled):active': {
            backgroundColor: 'var(--mui-palette-primary-lighterOpacity)',
          },
          '&.Mui-disabled': {
            opacity: 0.45,
            color: 'var(--mui-palette-primary-main)',
          },
        },
      },
      {
        props: { color: 'secondary' },
        style: {
          '&:not(.Mui-disabled):hover, &:not(.Mui-disabled):active': {
            backgroundColor: 'var(--mui-palette-secondary-lighterOpacity)',
          },
          '&.Mui-disabled': {
            opacity: 0.45,
            color: 'var(--mui-palette-secondary-main)',
          },
        },
      },
      {
        props: { color: 'error' },
        style: {
          '&:not(.Mui-disabled):hover, &:not(.Mui-disabled):active': {
            backgroundColor: 'var(--mui-palette-error-lighterOpacity)',
          },
          '&.Mui-disabled': {
            opacity: 0.45,
            color: 'var(--mui-palette-error-main)',
          },
        },
      },
      {
        props: { color: 'warning' },
        style: {
          '&:not(.Mui-disabled):hover, &:not(.Mui-disabled):active': {
            backgroundColor: 'var(--mui-palette-warning-lighterOpacity)',
          },
          '&.Mui-disabled': {
            opacity: 0.45,
            color: 'var(--mui-palette-warning-main)',
          },
        },
      },
      {
        props: { color: 'info' },
        style: {
          '&:not(.Mui-disabled):hover, &:not(.Mui-disabled):active': {
            backgroundColor: 'var(--mui-palette-info-lighterOpacity)',
          },
          '&.Mui-disabled': {
            opacity: 0.45,
            color: 'var(--mui-palette-info-main)',
          },
        },
      },
      {
        props: { color: 'success' },
        style: {
          '&:not(.Mui-disabled):hover, &:not(.Mui-disabled):active': {
            backgroundColor: 'var(--mui-palette-success-lighterOpacity)',
          },
          '&.Mui-disabled': {
            opacity: 0.45,
            color: 'var(--mui-palette-success-main)',
          },
        },
      },
    ],
  },
};

export default iconButton;
