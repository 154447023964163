'use client';

import { LDProvider } from 'launchdarkly-react-client-sdk';
import type { PropsWithChildren } from 'react';

import { useUser } from './useUser';

interface EONLDProviderProps extends PropsWithChildren {
  clientId?: string;
}

export const EONLDProvider = (props: EONLDProviderProps) => {
  const { currentProjectId } = useUser();
  if (!currentProjectId) {
    return null;
  }

  if (!props.clientId) {
    return props.children;
  }

  return (
    <LDProvider
      clientSideID={props.clientId}
      deferInitialization={true}
      context={{
        key: currentProjectId || '',
      }}
      options={
        {
          /* ... */
        }
      }
    >
      {props.children}
    </LDProvider>
  );
};
